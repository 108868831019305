import { filterGeos } from '@utils/GeoUtils'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { pieColors } from '../dashHelper'


export const PRODUTURO_PIE_COLORS = [
  '#015c52',
  '#33b9ce',
  '#f38625',
  '#669D20',
  '#D6EE4B',
  '#FFD01B',
  '#FF0000',
  '#990001'
]

export const getCmID = (code: string): number => {
  if (code === 'venta') return 9951
  else if (code === 'servicio' || code === 'postventa') return 9952
  else return 29
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary
}
