import { pieColors, yesNoMapper } from '../dashHelper'



export const widgetsPies: CommonWidget = {

  /**************************************************************************
   *                                PIES
  ***************************************************************************/

  pieGenericSmall: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 44],
    // [width, height]
    size: [1, 2],
    config: {
      id: 'pie-generic-small',
      type: 'pie',
      colors: pieColors,
      radius: [40, 85],
      center: ['40%', '55%'],
      showValues: true,
      showSample: true,
      decimals: 2,
      legendTop: 'center',
      legendOrient: 'vertical',
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'count', 'value', 'groupName'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  pieGenericMedium: {
    title: 'FALTA',
    // [Column, Row]
    position: [2, 44],
    size: [2, 2],
    config: {
      id: 'pie-generic-medium',
      type: 'pie',
      fillMode: '',
      colors: pieColors,
      showValues: true,
      showSample: false,
      //mapper: groupMapperLateral,
      itemWidth: 80,
      itemHeight: 20,
      //valueFormat: ' >-.1%',
      arcLabelsRadiusOffset: 1.15,
      itemDirection: 'left-to-right',
      anchor: 'top-left',
      direction: 'column',
      marginLeft: 250,
      decimals: 2,
      legendTranslateX: -200,
      radius: [40, 85],
      center: ['40%', '55%'],
      indicators: [
        {
          indicator: 'FALTA',
          //grouped: 'geoLocation',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {},
        },
      ],
    },
  },
  pieGenericLarge: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'pie-generic-large',
      type: 'pie',
      fillMode: '',
      colors: pieColors,
      showValues: true,
      showSample: false,
      itemWidth: 80,
      itemHeight: 20,
      itemGap: 17,
      //valueFormat: ' >-.1%',
      arcLabelsRadiusOffset: 1.15,
      itemDirection: 'left-to-right',
      anchor: 'top-left',
      direction: 'column',
      center: ['25%', '55%'],
      decimals: 2,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {},
        },
      ],
    },
  },
  pieSiNo: {
    title: '',
    position: [0, 0],
    size: [1, 2],
    config: {
      id: 'pie-si-no',
      type: 'pie',
      colors: pieColors,
      radius: [40, 85],
      center: ['40%', '55%'],
      showValues: true,
      showSample: false,
      decimals: 2,
      legendTop: 'bottom',
      legendOrient: 'vertical',
      mapper: yesNoMapper,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['group', 'count', 'value', 'groupName'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
}
