import useClientConfig from 'config/clients/useClientConfig'
import dayjs from 'dayjs'
import _ from 'lodash'

/**
 * Parse the filters names and formats.
 * @param {Object} filters
 * @returns {Object}
 */
export default (filters: FiltersUI, extras?: any): Filters => {
  const {
    includesFilterGeo,
    includesFilterLogical,
    includesFilterCriticalMoment,
    includesFilterDynamic
  } = useClientConfig()
  //console.log('Parsing filters indicator ')
  //console.log(filters)
  //console.log(extras)

  const newExtras = extras
    ? Object.keys(extras)
      .filter((key) => key !== 'criticalMomentId')
      .reduce((obj, key) => {
        const value = extras[key]
        if (value !== undefined && value !== null) {
          return Object.assign(obj, { [key]: value, })
        }

        return obj
      }, {})
    : extras

  if (!filters) {
    return {}
  }

  const dates = {
    dateFrom: dayjs(filters.fromDate).format('YYYY-MM-DD'),
    dateTo: dayjs(filters.toDate).format('YYYY-MM-DD'),
  }

  const criticalMomentInclude =
    extras !== undefined
      && extras.criticalMomentId !== undefined
      ? [extras.criticalMomentId]
      : (filters.criticalMoments !== undefined 
        && filters.criticalMoments !== null
        && filters.criticalMoments.length > 0
        ? filters.criticalMoments
        : [])

  const clear = (filters: FiltersUI): Filters => {
    const safetyFilters = [
      'groupByTopLocation',
      'alertStatusInclude',
      'alertStatus',
      'alertTypeInclude'
    ]
    return _.pick(filters, safetyFilters)
  }

  const responseValues = {}
  if (includesFilterDynamic() || extras?.extraResponseValues) {
    var rvs = {}

    if (includesFilterDynamic() && filters.responseValues !== undefined) {
      rvs = { ...rvs, ...filters.responseValues }
    }

    if (extras && extras.extraResponseValues) {
      rvs = { ...rvs, ...extras.extraResponseValues }
    }

    responseValues['responseValues'] = Object.keys(rvs).length > 0 ? rvs : undefined
  }

  // Critical Moments
  const criticalMomentFilters = includesFilterCriticalMoment()
    ? { criticalMomentInclude: criticalMomentInclude }
    : {}

  // Geo Locations
  const geoLocationFilters = includesFilterGeo()
    ? {
      geoLocationInclude: filters.geographicDistributions
        ? filters.geographicDistributions : []
    } : {}

  // Logical Locations
  const logicalLocationFilters = includesFilterLogical()
    ? {
      logicalLocationInclude: filters.logicalDistributions
        ? filters.logicalDistributions : []
    } : {}

  return {
    ...dates,
    ...clear(filters),
    ...criticalMomentFilters,
    ...geoLocationFilters,
    ...logicalLocationFilters,
    ...responseValues,
    ...newExtras,
  }
}
